import './index.css'

export function HamburgerIcon(){
    return (
        <div class="hamburger-container">
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}